@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  --primary-50: #fffce7;
  --primary-100: #fff7c3;
  --primary-200: #fff29c;
  --primary-300: #ffed75;
  --primary-400: #ffe855;
  --primary-500: #fee336;
  --primary-600: #ffd335;
  --primary-700: #fcbb2f;
  --primary-800: #faa328;
  --primary-900: #f57a1e;

  --text: #373737;
  --border: #d9d9d9;
  --dark-border: #0000001a;
  --disabled: #dee9f9;
  --accent-green: #00a386;
  --light-green: #79ceba;
  --bg-blue: #f4f5fb;
  --bg-green: #cce6cf;
  --illust: #f1d995;
  --illust-tealblue: #85cdc5;
  --pale-gray: #f8fbff;
}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'A1 Gothic R', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #373737;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* overflow-y: hidden; */
}

html.block {
  overflow: hidden !important;
}

.font-medium {
  font-family: 'A1 Gothic M', sans-serif;
}

.font-semibold {
  font-family: 'A1 Gothic B', sans-serif;
}

.background-morning {
  background-image: url('/public/images/top/morning/pc-morning-bg.webp');
}

.background-afternoon {
  background-image: url('/public/images/top/afternoon/pc-afternoon-bg.webp');
}

.background-evening {
  background-image: url('/public/images/top/evening/pc-evening-bg.webp');
}

.background-sp-footer {
  background-image: url('/public/images/top-sp-extend/footer/background.webp');
}

.background-point-pc-part-02 {
  background-image: url('/public/images/point/part-02/bg.webp');
}

.background-point-sp-part-02 {
  background-image: url('/public/images/point-sp/part-02/bg.webp');
}

.background-point-pc-part-04 {
  background-image: url('/public/images/point/part-04/bg.webp');
}

.background-point-sp-part-04 {
  background-image: url('/public/images/point-sp/part-04/bg.webp');
}

.background-extend-pc-devices {
  background-image: url('/public/images/top-extend/devices/background.webp');
}

.background-extend-sp-devices {
  background-image: url('/public/images/top-sp-extend/devices/background.webp');
}

.background-point-sp-part-06 {
  background-image: url('/public/images/point-sp/part-06/bg.webp');
}

.bg-pc-first-view {
  background-image: url('/public/images/top/first-view/pc-first-view-bg.webp');
}

@media only screen and (max-width: 743px) {
  .sp-version {
    display: block;
  }

  .pc-version {
    display: none;
  }
}

@media only screen and (min-width: 744px) {
  .sp-version {
    display: none;
  }

  .pc-version {
    display: 'block';
  }
}

html {
  --dvh: 100vh;
}

.h-dvh {
  height: var(--dvh);
}

.min-h-dvh {
  min-height: var(--dvh);
}

.max-h-dvh {
  max-height: var(--dvh);
}

.max-h-90dvh {
  max-height: calc(0.9 * var(--dvh));
}

.pt-dvh {
  padding-top: var(--dvh);
}

.top-50dvh {
  top: calc(0.5 * var(--dvh));
}

.header-sp-blur {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) -15%,
    rgba(255, 255, 255, 0) 113%
  );
  backdrop-filter: blur(2px);
}
